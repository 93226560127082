<template>
    <div>
        <h2>{{ $t("horse.historique") }}</h2>

        <div class="box">
            <CustomTable
                ref="horse_pension"
                id_table="horse_pension_historique"
                :items="horse_pension"
                :busy.sync="table_busy"
                primaryKey="horsepension_id"
                :hide_if_empty="true"
                :rawColumns="rawColumnNames"
				:display_action_button="!horseFicheReadOnly"
				:checkboxes="!horseFicheReadOnly"
            />
        </div>
    </div>
</template>

<script type="text/javascript">
    import TableAction from "@/mixins/TableAction.js"
    import Navigation from "@/mixins/Navigation.js"
    import HorseMixin from "@/mixins/Horse.js"
    import PensionMixin from "@/mixins/Pension.js"
    import Tools from "@/mixins/Tools.js"
    import { EventBus } from 'EventBus'
    import _orderBy from 'lodash/orderBy'
    import Common from '@/assets/js/common'
    import Vue from 'vue'

    export default {
        name: "PensionHistorique",
        mixins: [TableAction, Navigation, HorseMixin, PensionMixin, Tools],
        props: ['horse_id'],
        data () {
            return {
                horse_pension: null,
                rawColumnNames: ['pension_articles', 'pension_ht', 'pension_vat', 'pension_ttc'],
                table_busy: true,
                events_tab: {
                    'TableAction::goToDeleteHorsePensionHistorique': (params) => {
                        this.deleteHorsePension(params.horsepension_id)
                        .then(() => {
                            this.unselectAll()
                        })
                    }
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                await this.loadPensionHistoriqueComponent()
            },
            async loadPensionHistoriqueComponent() {
                this.table_busy = true
                const pensions = await this.loadHorsePension(this.horse_id)
                if(pensions) {
                    let temp = []
                    await Common.asyncForEach(
                        pensions,
                        async (pension) => {
                            if(pension.pension) {
                                if(pension.horsepension_end) {
                                    const pension_startdate = this.formatDate(pension.horsepension_start)
                                    const pension_enddate = this.formatDate(pension.horsepension_end)

                                    pension.pension_label = pension.pension.pension_label
                                    pension.pension_date = this.getTrad("horse.periode_du") + " " + pension_startdate + " " + this.getTrad("global.au") + " " + pension_enddate
                                    pension.pension_start = pension.horsepension_start
                                    pension.pension_articles = ''
                                    pension.pension_ht = ''
                                    pension.pension_vat = ''
                                    pension.pension_ttc = ''

                                    if(pension.horsepension_article) {
                                        const articles = pension.horsepension_article
                                        await Common.asyncForEach(
                                            articles,
                                            async (article) => {
                                                pension.pension_articles = pension.pension_articles + article.horsepensionarticle_article.articles_label + "<br>"
                                                pension.pension_ht = pension.pension_ht + await this.priceFormat(article.horsepensionarticle_ht, article.tiers_currency) + "<br>"
                                                pension.pension_vat = pension.pension_vat + article.horsepensionarticle_article.vat.vat_label + "<br>"
                                                pension.pension_ttc = pension.pension_ttc + await this.priceFormat(article.horsepensionarticle_ttc, article.tiers_currency) + "<br>"
                                            }
                                        )
                                    }

                                    temp.push(pension)
                                }
                            }
                        }
                    )

                    this.horse_pension = _orderBy(temp, 'pension_start', 'desc')
                } else {
                    this.horse_pension = null
                }

                this.table_busy = false
            },
            async deleteHorsePension(horsepension_id) {
                this.deletePension(this.horse_id, horsepension_id)
                .then(result => {
                    this.loadPensionHistoriqueComponent()
                    EventBus.$emit('DeleteHorsePensionHistoriqueSuccess')
                    this.successToast('toast.info_modif_succes')
                })
                .catch(e => {
                    this.failureToast('toast.info_save_failed')
                })
            },
            unselectAll() {
        		this.$refs.horse_pension.unselectAll()
        	}
        },
        computed: {
			horseFicheReadOnly() {
                return this.$store.state.horseFicheReadonly
            },
            horseProps: function() {
                return this.horse_id
            }
        },
        watch: {
            "horseProps" (val, prev) {
                if(val !== prev) {
                    this.init_component()
                }
            }
        },
        components: {
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable')
        }
    }
</script>
